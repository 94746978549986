<template>
  <auth-content>
    <el-header
      slot="header"
      :title="$t('notifications.title')"
      :favorites="false"
    />

    <div class="card">
      <div class="card-body p-1">

        <div v-for="(notif, notifIndex) in userNotifsForThisWorld" :key="notif.id" :class="{ 'py-2': true, 'border-bottom': notifIndex < userNotifsForThisWorld.length - 1 || next_page }">
          <component :is="`el-notification-${notif.data.module}-${notif.data.type}`" :notification="notif">
            <i>No {{ `el-notification-${notif.data.module}-${notif.data.type}` }} component</i>
          </component>
        </div>

        <div v-if="!loading && userNotifsForThisWorld.length == 0">

          <el-empty
            :title="$t('notifications.empty_notifications')"
          />
        </div>

        <div v-if="loading" class="text-center py-3">
          <loader />
        </div>
        <div v-else-if="next_page" class="btn btn-link btn-sm text-center w-100 text-secondary mt-2 font-weight-bold" @click="loadNotifs()">
          {{ $t('notifications.view_more') }}
        </div>

      </div>
    </div>

  </auth-content>
</template>

<script>

import _ from 'lodash'
import store from '../../store'
import { USER_NOTIFICATIONS_GET_ALL } from '../../store/mutations-types'

export default {
  name: 'UserProfileForm',
  data () {
    return {
      loading: true,
      notifications: [],
      next_page: 1,
    }
  },
  methods: {
    loadNotifs () {
      if (!this.next_page) {
        return
      }

      this.loading = true

      this.$store.dispatch('auth/' + USER_NOTIFICATIONS_GET_ALL, {
        worldId: this.$route.params.world,
        page: this.next_page,
      }).then(response => {
        this.notifications = this.notifications.concat(response.data)
        if (!response.next_page_url) {
          this.next_page = null
        } else {
          this.next_page++
        }
        this.loading = false
      })
    },
  },
  mounted () {
    this.loadNotifs()
  },
  computed: {
    userNotifsForThisWorld () {
      return _.filter(this.notifications, notif => {
        return notif.data.world_id == this.$route.params.world
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
